"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetectedApplications = void 0;
require("./DetectedApplications.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const DetectedApplicationCard_1 = require("./DetectedApplicationCard");
const sonar_core_1 = require("@sonar-software/sonar-core");
const general_1 = require("@ui/general");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const DetectedApplications = () => {
    const applications = (0, _hooks_1.usePulseApps)(false, true, true);
    const [showHidden, setShowHidden] = (0, _hooks_1.useBoolean)(false);
    const appsToShow = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = applications.filter((app) => app.status === sonar_core_1.PulseApplicationStatus.Detected ||
            (showHidden && app.status === sonar_core_1.PulseApplicationStatus.Deleted)).map((app) => app.applicationId)) !== null && _a !== void 0 ? _a : [];
    }, [applications, showHidden]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'detected-applications', extra: react_1.default.createElement(antd_1.Checkbox, { checked: showHidden, id: 'show-hidden-checkbox', onChange: setShowHidden.toggle }, sonar_ts_constants_1.COPY_SPECIFIC.SHOW_HIDDEN_APPLICATIONS), title: sonar_ts_constants_1.COPY_SPECIFIC.DETECTED_APPLICATIONS, titleClassName: 'h2' },
        react_1.default.createElement(antd_1.Row, { className: 'detected-applications-list' }, appsToShow.map((id) => react_1.default.createElement(antd_1.Col, { key: id },
            react_1.default.createElement(DetectedApplicationCard_1.DetectedApplicationCard, { applicationId: id }))))));
};
exports.DetectedApplications = DetectedApplications;
